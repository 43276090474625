// 订单管理
<template>
  <div
    class="card"
    v-loading="loading"
  >
    <div class="card_count tw-flex tw-justify-start tw-items-center">
      <div class="card_count_article">
        <p class="tag">发文量</p>
        <p class="count">{{ count.publishedCount }}</p>
      </div>
      <div class="card_count_check">
        <p class="tag">待审核</p>
        <p class="count">{{ count.submitCount }}</p>
      </div>
      <div class="card_count_visitor">
        <p class="tag">访问量</p>
        <p class="count">{{ count.views}}</p>
      </div>
    </div>
    <div class="card_chart">
      <div class="card_chart_range">
        <el-select
          v-model="rangeFlag"
          placeholder="本周/本月/今年"
          @change="getInfoData"
        >
          <el-option
            v-for="item in rangeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div
        class="card_chart_pane"
        ref="infoChartPane"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import dayjs from 'dayjs'
import * as api from '@/api'
import geLineOptions from './line-options'

export default {
  data () {
    return {
      loading: false,
      count: {
        submitCount: 0,
        publishedCount: 0,
        views: 0,
        xData: [],
        yData: []
      },
      // 默认为0 ==> 本周
      rangeFlag: 'week',
      rangeList: [
        {
          value: 'week',
          label: '本周'
        },
        {
          value: 'month',
          label: '本月'
        },
        {
          value: 'year',
          label: '今年'
        }
      ]
    }
  },
  async mounted () {
    this.loading = true
    await this.getInfoData()
    this.loading = false
  },
  methods: {
    async getInfoData () {
      // 获取发文量、待审核、访问量
      // 根据维度获取图表数据
      await api.getInfoChartData({
        type: this.rangeFlag
      }).then(res => {
        if (res.data.code === 0) {
          console.log(res.data.data)
          this.count.submitCount = res.data.data.submitCount
          this.count.publishedCount = res.data.data.publishedCount
          this.count.views = res.data.data.views
          // 加工xData、yData
          const chartData = this._chartDataHandler(res.data.data.xData, res.data.data.yData)
          console.log(chartData)
          this.count.xData = chartData.xData
          this.count.yData = chartData.yData
          this.renderChart({ xData: this.count.xData, yData: this.count.yData })
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },
    _chartDataHandler (x = [], y = []) {
      const xData = []
      const yData = []
      // 没有访问量，直接返回空对象
      if (x.length === 0) {
        return { xData, yData }
      }
      const rangeFlag = this.rangeFlag
      if (rangeFlag === this.rangeList[0].value) { // 本周
        return { xData: x, yData: y }
      } else if (rangeFlag === this.rangeList[1].value) { // 本月
        const dateTmp = dayjs(x[0]) // x[0]数据格式为 'MM-DD'，假设x[0] = '08-07'
        const thisYear = dayjs().year() // 直接从客户端得到本年年份(可能存在误差，概率较小): 2021
        const thisMonth = dateTmp.month() + 1 // 得到本月的月份: 8
        const maxDay = dayjs(thisYear + '-' + thisMonth + '-' + 0).date() // 得到本月的最大号数：31
        for (let i = 1; i <= maxDay; i += 3) { // 初始化xData为(三天一段)['8-1','8-4','8-7','8-10','8-13',...,'8-31']
          xData.push(thisMonth + '-' + i) // yData对应初始化为0
          yData.push(0)
        }
        for (let i = 0; i < x.length; i++) { // 取出后端返回的x，x格式为'YYYY-MM-DD'
          // 1,4,7,10,13...
          // 能被整除则修改访问量，否则不修改
          const flag = (dayjs(x[i]).date() - 1) % 3
          if (flag === 0) {
            const index = (dayjs(x[i]).date() - 1) / 3 // 找到x[i]这天在xData数组中的下标
            yData[index] = y[i] // 根据下标对yData做出修改
          }
        }
      } else if (rangeFlag === this.rangeList[2].value) { // 今年
        // 初始化xData，yData数组
        for (let i = 1; i <= 12; i++) {
          xData.push(i + '月')
          yData.push(0)
        }
        for (let i = 0; i < x.length; i++) {
          // 格式1 ==> x轴数据格式 '2021-08'
          // const monthIndex = dayjs(x[i]).month()
          // 格式2 ==> x轴数据格式 '8月'
          const monthIndex = parseInt(x[i]) - 1
          yData[monthIndex] = y[i]
        }
      }
      return { xData, yData }
    },
    renderChart (data) {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.infoChartPane)
      }
      const options = geLineOptions(data)
      this.chart.setOption(options, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 980px;
  height: 724px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &_count {
    > * {
      width: 240px;
      height: 80px;
      position: relative;
      color: #ffffff;
      margin-right: 10px;
      > .tag {
        padding-left: 12px;
        margin-top: 12px;
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      > .count {
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 20px;
        margin-top: 4px;
        width: 100%;
        height: 33px;
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        opacity: 1;
      }
    }
    &_article {
      background: url("../../../assets/images/info-stats/count_article_sended.png")
        no-repeat;
    }
    &_check {
      background: url("../../../assets/images/info-stats/count_check_pending.png")
        no-repeat;
    }
    &_visitor {
      background: url("../../../assets/images/info-stats/count_visitor.png")
        no-repeat;
    }
  }
  &_chart {
    &_range {
      margin: 20px 0 10px 0;
    }
    &_pane {
      width: 940px;
      height: 542px;
    }
  }
}
</style>
