export default function (parms = {}) {
  const { xData = [], yData = [] } = parms
  const options = {
    // 提示框组件
    // 可用在全局 | 坐标系 | 系列 | 系列的每个数据项
    // 比如折线图中每一点数据解释
    tooltip: {
      // 是否显示
      show: true
    },
    // 图例组件
    // 比如首页折线图中左上角的图例，展示了相应折线的标记、颜色、名字
    // 点击图例可控制是否显示
    legend: {
      show: true,
      // 图例组件离容器左侧的距离
      // 具体像素值 20
      // 父容器百分比 20%
      // ‘left’ ‘center’
      left: 10
    },
    // 直角坐标系内绘图网格
    // 可绘制任意多个坐标
    grid: [{
      top: 50,
      bottom: 35,
      left: 40,
      right: 20
    }],
    // 直角坐标系grid中的y轴
    yAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#DCDFE6'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#909399'
      },
      splitLine: {
        lineStyle: {
          color: '#EEF0F5'
        }
      }
    },
    xAxis: {
      data: xData,
      nameTextStyle: {
        width: 800
      },
      axisLine: {
        lineStyle: {
          color: '#DCDFE6'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#909399'
      },
      axisPointer: {
        show: true
      }
    },
    series: {
      type: 'line',
      name: '访问量',
      data: yData,
      lineStyle: {
        // color: '#3473E6',
        color: '#FF4343',
        shadowColor: 'rgba(52, 115, 230, 0.2)',
        shadowBlur: 2,
        shadowOffsetY: 13
      },
      itemStyle: {
        // color: '#3473E6'
        color: '#FF4343'
      }
    }
  }

  return options
}
